const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_zym3aCbQ3",
    "aws_user_pools_web_client_id": "2n15lhk845sucm0k4fejjqcbev",
    "aws_identity_pool_id": "us-west-2:7f74c720-5f61-4b1d-b9fd-81ae626cfd40"
};

export default awsmobile;

